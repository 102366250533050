import { EventBus } from "../eventBus/nav-bar-event";
import globals from "../globals";

// Notification Mixin
export default {
    data() {
      return {
        notificationsList: [],
        notificationsCount: 0,
        count: 0,
      };
    },
    methods: {
      // Getting All Notification
      getNotificationRequests(request_type) {
        this.notificationsList = []
        this.ajaxCallinprogress = true;
        var request_type = 'personal'
        if(this.account)
        request_type = 'employee';
        this.$http.post(globals.CRM_API + "/notifications/list", {
        //this.$http.post("/notifications/list", {
            skip: this.skip,
            limit: 10,
            only_crm: false,
            // product_id: "",
            search_key: "",
          })
          .then(res => {
            console.log(res.data);
            if (res.data.status_id == 1) {
              this.notificationsList = res.data.response;
              this.count = this.notificationsList.length
              this.notificationsCount = res.data.count
              this.ajaxCallinprogress = false;
            }
          });
      },
      // Delete Notification API
      deleteNotificationRequests(id) {
        this.$http
          .post("/notifications/delete", {
            notifications : [id]
          })
          .then(res => {
            if (res.data.status_id == 1) {
              EventBus.$emit("updateNotification")
              this.$awn.success("Notification Deleted Successfully")
            }
            else {
              this.$awn.alert("Something Went Wrong! Please try again")
            }
            this.getNotificationRequests()
          });
      },
      // Mark as Read Notification
      notificationRead(id) {
        this.$http
          .post("/notifications/mark_notifications_as_read", {
            notifications : [id]
          })
          this.getNotificationRequests()
      },
    },
  };
  