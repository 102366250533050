// Employee Mixin
export const EmployeeManage = {
  data() {
    return {};
  },
  methods: {
    // Getting Employee List
    getEmployees(status) {
      this.employeeData = [];
      this.ajaxCallinprogress = true;
      // if(this.searchKey != '' && this.searchKey.length == 1) {
      //   this.skip = 0
      // }
      this.$http
        .post("/employees/get_employees", {
          skip: this.skip,
          limit: this.limit,
          search_key: this.searchKey,
          active_status: status,
          company_id: this.$route.params.id,
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.employeeData = res.data.response;
            this.ajaxCallinprogress = false;
            this.total = res.data.total
          }
        });
    },
    async updateEmployeeInfo(params, id) {
      return new Promise(async (resolve, reject) => {
          try {
              let response = await this.$http.patch(global.AUTH_SERVICE + `/employees/${id}`, params)
              if (response.data.status_id == 1) {
                  resolve(response.data)
              } else {
                  reject(response.data.reason)
              }
          }
          catch(error) {
              reject(error.response.data.reason)
          }
      })
    },
    // Changing Employee Status
    changeEmployeeStatus(status, id) {
      this.$http
        .post("/employees/change_employee_status", {
          status: status,
          employee_id: id,
        })
        .then(res => {
          if (res.data.status_id == 1) {
            if (status=="active"){
              this.$awn.success("Request Accepted Successfully")
            }
            if (status=="ignore"){
              this.$awn.success("Ignored Successfully")
            }
            this.getEmployees(status)
            this.getUserEmployeeRequest()
          }
          else {
            this.$swal({
              title: "Error!",
              text: "Please try again",
              type: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#0475f3",
            });
          }
        });
    },

    // Checking Employee Exists
    async checkIfEmployeeExists() {
      return new Promise(async (resolve, reject) => {
        let response = await this.$http.post("/employees/get_employee_status", {
          user_id: this.$route.params.id,
        });
        if (response.data.status_id == 1) {
          resolve(response.data);
        } else {
          reject(response.reason);
        }
      });
    },
    // Getting Employee Details
    async getEmployeeDetailsAPI() {
      return new Promise(async (resolve, reject) => {
        let response = await this.$http.post("/employees/get_employee_data", {
          emp_id: this.$route.params.id,
        });
        if (response.data.status_id == 1) {
          resolve(response.data);
        } else {
          reject(response.reason);
        }
      });
    },
  },
};
